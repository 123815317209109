import { OrbitControls } from '@react-three/drei'
import { extend, useFrame, useLoader, useThree } from '@react-three/fiber'
import { Perf } from 'r3f-perf'
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'
import Particles from './Particles'
import { Bloom, Noise, Glitch, Vignette, EffectComposer } from '@react-three/postprocessing'
import { useControls } from 'leva'
import * as THREE from 'three'
import { useMemo, useRef, useEffect } from 'react'


export default function Experience() {

    let isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    console.log(isMobile);


    const mouse = useRef([0, 0])

    const model = useLoader(OBJLoader, './crowd_conversion_logo_points.obj')

    const logoPointsPositions = model.children[0].geometry.attributes.position.array;

    let positions = new Float32Array(logoPointsPositions.length);


    if (isMobile) {
        positions = new Float32Array(logoPointsPositions.length);
        for (let i = 0; i < logoPointsPositions.length; ++i) {
            positions[i] = logoPointsPositions[i]
        }
    } else {
        let k = 0;

        for (let i = 0; i < logoPointsPositions.length; ++i) {
            positions[k] = logoPointsPositions[i]
            k += 1;
        }

    }

    const config = {
        lightPosition_x: -5,
        lightPosition_y: 10,
        lightPosition_z: 10,
        colorFactor: 3,
        cameraHeight: 16,
        focalLength: 0.2,
        focusDistance: 0,
        lightIntensity: 1,
        lightDistance: 0
    }

    let redColorHex = new THREE.Color('#DF4242');
    redColorHex = new THREE.Color('#2E96E7');
    const redColor = [redColorHex.r * config.colorFactor, redColorHex.g * config.colorFactor, redColorHex.b * config.colorFactor]

    const count = positions.length / 3;

    const colorArray = useMemo(
        () => {
            const colorArray = new Float32Array(count * 3)

            for (let i = 0; i < count; ++i) {
                colorArray[i * 3 + 0] = redColor[0];//darkBlue.r * config.colorFactor;
                colorArray[i * 3 + 1] = redColor[1];//darkBlue.g * config.colorFactor;
                colorArray[i * 3 + 2] = redColor[2];//darkBlue.b * config.colorFactor;
            }
            return colorArray;
        }, [])

    return <>
        {/* <color attach="background" args={['#000000']} /> */}

        {/* <Perf position="top-left" /> */}

        <pointLight
            color={'white'}
            intensity={config.lightIntensity}
            distance={config.lightDistance}
            position={[config.lightPosition_x, config.lightPosition_y, config.lightPosition_z]} />

        <Particles
            positions={positions}
            initialPositions={positions}
            desiredPositions={positions}
            finalColor={redColor}
            colorArray={colorArray}
            mouse={mouse}
            isMobile={isMobile} />

        <ambientLight
            color={'white'}
        />

        <EffectComposer>
            <Bloom mipmapBlur luminanceThreshold={0.9} radius={0.7} />
        </EffectComposer>

    </>
}