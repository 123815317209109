import { OrbitControls } from '@react-three/drei'
import { useLoader, useFrame, useThree } from '@react-three/fiber'
import { useEffect, useMemo, useRef, useState } from 'react'
import ParticleMaterial from './Shaders/ParticleMaterial.js';
import CustomShaderMaterial from 'three-custom-shader-material'

import * as THREE from 'three'

function useScreenSize() {
    const isClient = typeof window === "object";

    function getSize() {
        return {
            width: isClient ? window.innerWidth : undefined,
            height: isClient ? window.innerHeight : undefined
        };
    }

    const [windowSize, setWindowSize] = useState(getSize);

    useEffect(() => {
        if (!isClient) {
            return false;
        }

        function handleResize() {
            setWindowSize(getSize());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount and unmount

    return windowSize;
}

function lerp(v0, v1, t) {
    return v0 * (1 - t) + v1 * t;
}
const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

const screenToWorld = (camera, x, y) => {
    /* https://stackoverflow.com/a/13091694 */

    /* x and y are normalized from 0 to 1,
        0, 0 -- top left
     */

    var vec = new THREE.Vector3();
    var pos = new THREE.Vector3();

    vec.set(x * 2 - 1, -y * 2 + 1, 0);
    vec.unproject(camera);

    vec.sub(camera.position).normalize();

    var distance = - camera.position.y / vec.y;

    pos.copy(camera.position).add(vec.multiplyScalar(distance));

    return pos;
}

export default function Particles({ positions, initialPositions, desiredPositions, colorArray, finalColor, isMobile }) {

    const { width, height } = useScreenSize();

    const [progress, setProgress] = useState(0)
    const [active, setActive] = useState({});
    const [hover, setHover] = useState(false);
    const [framesDeltas, setFramesDeltas] = useState(Array());



    const mesh = useRef()
    const count = positions.length / 3;

    const radius = 0.06

    let mouse = {
        x: 0,
        y: 0
    }


    useEffect(() => {
        console.log('SET INITIAL POSITIONS')

        document.getElementById("root_logo_animation").addEventListener("mouseleave", (event) => {
            setHover(false);
        });
        document.getElementById("root_logo_animation").addEventListener("mouseenter", (event) => { 
            setHover(true);
        });


        const dummyObject = new THREE.Mesh()
        for (let i = 0; i < count; ++i) {
            dummyObject.position.x = initialPositions[i * 3 + 0];
            dummyObject.position.y = initialPositions[i * 3 + 1];
            dummyObject.position.z = initialPositions[i * 3 + 2];
            dummyObject.updateMatrix()

            mesh.current.setMatrixAt(i, dummyObject.matrix)
        }

        mesh.current.instanceMatrix.needsUpdate = true;

    }, []);


    let frac = clamp(Math.sqrt(progress / (count / 2), 2), 0, 1);

    const mobileMaterialRef = useRef()
    const nOfFramesToMonitor = 30



    let lerpSpeed = 0.025;
    if (isMobile) {
        lerpSpeed = 0.1;
    }

    useFrame((state, delta, xrFrame) => {

        let tempFramesDeltas = framesDeltas.slice();
        if (tempFramesDeltas.length > nOfFramesToMonitor) {
            tempFramesDeltas.shift()
        }

        tempFramesDeltas.push(delta)

        const sum = tempFramesDeltas.reduce((partialSum, a) => partialSum + a, 0);
        const FPS = 1 / (sum / nOfFramesToMonitor);


        if (tempFramesDeltas.length == nOfFramesToMonitor && state.clock.elapsedTime > 0.5) {
            if (FPS < 45) {
                if (state.gl.getPixelRatio() > 1.0) {
                    state.gl.setPixelRatio(state.gl.getPixelRatio() / 2);
                    tempFramesDeltas = []
                }
            }
            if (FPS > 50) {
                if (state.gl.getPixelRatio() < 2) {
                    state.gl.setPixelRatio(state.gl.getPixelRatio() * 2);
                }
                tempFramesDeltas = []
            }
        }

        setFramesDeltas(tempFramesDeltas)

        if (!mesh.current) {
            return
        }
        if (window.scrollY >= 900) {
            return
        }

        const mousePos = screenToWorld(state.camera, (state.pointer.x + 1) / 2, 1 - (state.pointer.y + 1) / 2)
        
        const dummyObject = new THREE.Mesh()
        let tempMatrix = new THREE.Matrix4()
        let tempVector = new THREE.Vector3()


        let copiedActive = { ...active };
        let frameProgress = progress;

        if (isMobile) {
            mobileMaterialRef.current.uniforms.uMouse.value = new THREE.Vector3(mousePos.x, 0, mousePos.z)

            mobileMaterialRef.current.uniforms.uTime.value = state.clock.elapsedTime;


            frac = 1;

            mesh.current.geometry.attributes.color.setXYZ(progress, finalColor[0], finalColor[1], finalColor[2]);

            setProgress(progress + 1);

        } else {
            for (let i = 0; i < count; ++i) {
                mesh.current.getMatrixAt(i, tempMatrix);
                tempVector.setFromMatrixPosition(tempMatrix);

  
                    if (hover){ 
                        let dir = new THREE.Vector3().subVectors(mousePos, tempVector);
    
                        let dist = dir.lengthSq();
                        if (dist === 0) {
                            dist = 0.01;
                        }
        
                        dir = dir.normalize().multiplyScalar(-0.02);
                        dir = dir.divideScalar(dist / 2);
                        tempVector.add(dir);
                    }

                    tempVector = new THREE.Vector3(
                        lerp(tempVector.x, desiredPositions[i * 3 + 0], 0.1),
                        lerp(tempVector.y, desiredPositions[i * 3 + 1],  0.1),
                        lerp(tempVector.z, desiredPositions[i * 3 + 2], 0.1),
                    )
   

                dummyObject.position.x = tempVector.x;
                dummyObject.position.y = tempVector.y;
                dummyObject.position.z = tempVector.z;

                dummyObject.updateMatrix();
                mesh.current.setMatrixAt(i, dummyObject.matrix);
            }

            setActive(active => ({
                ...copiedActive
            }));

            setProgress(frameProgress)

            frac = Math.sqrt(progress / count, 2);

        }


        mesh.current.instanceMatrix.needsUpdate = true;
        mesh.current.geometry.attributes.color.needsUpdate = true;

    })


    return <>

        <instancedMesh
            ref={mesh}
            args={[null, null, count]}>
            <sphereGeometry attach="geometry" args={[radius, 32, 8]}>
                <instancedBufferAttribute
                    attach="attributes-color"
                    count={count}
                    array={colorArray}
                    itemSize={3}
                    normalized={false}
                />

                <instancedBufferAttribute
                    attach="attributes-aStartPosition"
                    count={count}
                    array={initialPositions}
                    itemSize={3}
                    normalized={false}
                />
                <instancedBufferAttribute

                    attach="attributes-anEndPosition"
                    count={count}
                    array={positions}
                    itemSize={3}
                    normalized={false}
                />
            </sphereGeometry>
            {!isMobile &&
                <meshStandardMaterial attach="material" vertexColors toneMapped={false} />
            }

            {isMobile &&
                <CustomShaderMaterial
                    ref={mobileMaterialRef}
                    baseMaterial={THREE.MeshStandardMaterial}
                    vertexShader={/* glsl */`

    
                attribute vec3 aStartPosition;
                attribute vec3 anEndPosition;
    
    
                uniform vec3 uMouse;
                uniform float uTime;
    
                void main() {    
                    float frac = clamp(uTime / 4.0, 0.0, 1.0);


                    vec3 tempVector = mix(aStartPosition, anEndPosition, frac);
    
    
                    vec3 dir = uMouse - tempVector;
                    float dist = length(dir) + 0.01;
    
                    float distSq = dist * dist;
    
    
                    dir = normalize(dir) * vec3(-0.02);
                    dir = dir / vec3(distSq + 0.01);
    
                    tempVector += dir;
    
                    vec3 newPosition = position + tempVector;
    
                    csm_PositionRaw = projectionMatrix *  viewMatrix * modelMatrix * vec4( newPosition, 1.0 );
    
                }
            `}
                    uniforms={{
                        uTime: {
                            value: 0,
                        },

                        uMouse: {
                            value: new THREE.Vector3()
                        }
                    }}
                    vertexColors
                    toneMapped={false}
                />
            }


        </instancedMesh>
    </>
}