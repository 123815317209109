import * as THREE from "three"
import { extend, MaterialNode } from '@react-three/fiber'

export default class ParticleMaterial extends THREE.ShaderMaterial {
    constructor() {
        super({
            uniforms: {
                uColor: { value: new THREE.Color('red') },
                uLerpTexture: { value: null },
                uMouse: { value: new THREE.Vector3(0) },
                uTime: { value: 0 },
                uFrac: { value: 0 },
                uLogoPosition: { value: new THREE.Vector3(0) }
            },
            vertexShader: `
            // vertex color
            //attribute vec4 color;

            // attribute mat4 anInstanceMat;
            varying vec3 vColor;
            

            attribute vec3 aStartPosition;
            attribute vec3 anEndPosition;


            uniform vec3 uMouse;
            uniform float uFrac;
            uniform float uTime;
            uniform vec3 uLogoPosition;

            // attribute vec3 normal;
            varying vec3 vNormal;
            varying float vFrac;
            void main() {
                vColor = color;
                vNormal = normal;

                float frac = clamp(uTime / 6.0, 0.0, 1.0);
                vFrac = frac;
                vec3 tempVector = mix(aStartPosition, anEndPosition + uLogoPosition, frac);


                vec3 dir = uMouse - tempVector;
                float dist = length(dir) + 0.01;

                float distSq = dist * dist;


                dir = normalize(dir) * vec3(-0.02);
                dir = dir / vec3(distSq + 0.01);

                tempVector += dir;

                vec3 newPosition = position + tempVector;

               // vec3 aDesiredPosition = position +  mix(aStartPosition, anEndPosition + uLogoPosition, uFrac);


                gl_Position = projectionMatrix *  viewMatrix * modelMatrix * vec4( newPosition, 1.0 );

                //gl_Position = projectionMatrix * viewMatrix * modelMatrix * instanceMatrix * vec4(newPosition, 1.0);
            }
        `,
            fragmentShader: `

            varying vec3 vNormal;
            varying vec3 vColor;
            varying float vFrac;
            void main() {
                vec3 grayColor = vec3(10.0 / 255.0, 17.0 / 255.0, 33.0 / 255.0);
                vec3 blueColor = vec3(46.0 / 255.0, 150.0 / 255.0, 231.0 / 255.0);
                vec3 mixedColor = mix(grayColor, blueColor, vFrac);
                gl_FragColor = vec4((mixedColor + (vNormal * vec3(0.1))) * vec3(vFrac * 1.6), 1.0); 

            }`,
        })
    }

    get uColor() {
        return this.uniforms.uColor.value
    }
    set uColor(v) {
        this.uniforms.uColor.value = v
    }

    get uLerpTexture() {
        return this.uniforms.uLerpTexture.value
    }
    set uLerpTexture(v) {
        this.uniforms.uLerpTexture.value = v
    }

    get uLogoPosition() {
        return this.uniforms.uLogoPosition.value
    }
    set uLogoPosition(v) {
        this.uniforms.uLogoPosition.value = v
    }
}


extend({ ParticleMaterial })
