import ReactDOM from 'react-dom/client'
import { Canvas } from '@react-three/fiber'
import Experience from './Experience.js'
import * as THREE from 'three'
const root = ReactDOM.createRoot(document.querySelector('#root_logo_animation'))


root.render(
    <Canvas
        dpr={[1, 2]}
        camera={{
            fov: 50,
            near: 0.1,
            far: 512,
            position: [0, 5, 0],
        }}
    >
        <Experience />

    </Canvas>
)